<template>
  <div class="liff-staff-list">

    <div class="liff-staff-list__content" :class="{ 'need-bottom': !!chosenStaff.id }">
      <b-card v-for="staff in staffList" :key="staff.id" class="liff-staff-list__card" :ref="staff.id">
        <input type="radio" :id="staff.id" class="liff-staff-list__checkbox" :value="staff" v-model="chosenStaff">
        <label :for="staff.id" class="liff-staff-list__content-item" :class="{ 'active': chosenStaff.id === staff.id }">
          <img class="liff-staff-list__content-item-img" :src="staff.img" alt="">
          <div class="liff-staff-list__content-item-info">
            <div class="s-flex s-flex-col s-gap-2">
              <div>{{ staff.name }}</div>
              <div class="s-text-gray s-text-sm">{{ staff.position }}</div>
              <div v-if="staff.description" class="s-mt-3 s-mb-3 s-border-b s-border-gray-light"></div>
              <div v-if="staff.description" class="s-text-gray s-text-xs">{{ staff.description }}</div>
            </div>
          </div>
          <BIconCheckCircleFill v-if="chosenStaff.id === staff.id" :height="20" class="s-text-primary liff-staff-list__content-item-check" />
        </label>
      </b-card>
    </div>

    <div v-if="!!chosenStaff.id" class="liff-staff-list__confirm">
      <div class="s-flex s-justify-between s-items-center s-mb-3">
        <div class="s-font-medium">已選：<span class="s-text-primary">{{ chosenStaff.name }}</span></div>
        <div class="s-text-primary cursor-pointer s-font-medium s-flex-shrink-0" @click="chosenStaff = {}">重選</div>
      </div>
      <div class="s-flex s-justify-between s-items-center" style="gap: 8px;">
        <button
          class="s-btn s-liff-theme-btn-outline s-btn-sm"
          type="button"
          @click="prevStep"
        >
        上一步
        </button>
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          @click="nextStep">
        下一步
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { BIconCheckCircleFill } from 'bootstrap-vue';

  export default {
    components: {
      BIconCheckCircleFill,
    },
    data() {
      return {
        chosenStaff: {},
        staffList: [
          {
            id: 1,
            name: 'Tina',
            position: '首席設計師',
            img: 'https://picsum.photos/200/300'
          },
          {
            id: 2,
            name: 'Debby',
            position: '資深設計師',
            img: 'https://picsum.photos/200/300'
          },
          {
            id: 3,
            name: 'Nymphia',
            position: '資深設計師',
            img: 'https://picsum.photos/200/300',
            description: '美國洛杉磯出生，在台北和香港兩地間長大，並於6歲時隨母親搬回台灣'
          },
          {
            id: 4,
            name: 'Isobe',
            position: '實習設計師',
            img: 'https://picsum.photos/200/300',
          },
          {
            id: 5,
            name: 'Jeux',
            position: '實習設計師',
            img: 'https://picsum.photos/200/300',
            description: '實習1個月非常推薦有淺力的設計師'
          },
          
        ]

      }
    },
    methods: {
      prevStep() {
        this.$router.push({
          name: 'LiffReservationService'
        })
      },
      nextStep() {
        // this.$router.push({
        //   name: 'LiffReservationService'
        // })
      }
    }
  }
</script>

<style lang="scss" scoped>
.liff-staff-list {
  
  &__checkbox {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 16px 16px;
    &.need-bottom {
      margin-bottom: 150px;
    }

    &-item {
      display: flex;
      align-items: center;
      border: 1px solid #E5E5EA;
      border-radius: 10px;
      padding: 12px;
      color: #2C2C2E;
      font-size: 18px;
      font-weight: 500;
      gap: 12px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #F5F5F5;
      }
      &.active {
        border: 2px solid var(--s-primary);
      }
      &-img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        flex-shrink: 0;
      }
      &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
      }
      &-check {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }
  }

  &__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .card-body {
      padding: 0;
    }
	}

  &__confirm {
    position: fixed;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

</style>